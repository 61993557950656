<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title v-if="editar">
          Editar Local Impressão *
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Novo Local Impressão *
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSave"
        >
          <v-icon class="white--text">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <!-- <v-text-field
                v-model="caixadados.cdloja"
                label="Loja"
                required
              /> -->
              <componenteLoja
                v-if="carregarCombo"
                :id="localImpressaodados.cdloja"
                dense
                @onSelectLoja="onSelectLoja"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <componenteUsuario
                v-if="carregarCombo"
                :id="localImpressaodados.cdusuario"
                dense
                @onSelectUsuario="onSelectUsuario"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <componenteImpressora
                v-if="carregarCombo"
                :id="localImpressaodados.cdimpressora"
                dense
                @onSelectImpressora="onSelectImpressora"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="localImpressaodados.cdimpressoracertificado"
                label="Certificado Impressora"
                :rules="cdImpressoraCertificadoRules"
                :counter="40"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>
<script>
  import localImpressaoRep from '../../components/services/localimpressaoRepository'
  import componenteLoja from './componente-loja'
  import componenteUsuario from './componente-usuario'
  import componenteImpressora from './componente-impressora'

  export default {
    components: {
      componenteLoja,
      componenteUsuario,
      componenteImpressora,
    },
    props: {
      editar: {
        type: Boolean,
        required: true,
      },
      cdloja: {
        type: Number,
        default: 0,
        required: false,
      },
      cdusuario: {
        type: String,
        default: '',
      },
      cdimpressora: {
        type: String,
        default: '',
      },
      cdimpressoracertificado: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        localImpressaodados: {
          // cdloja: 0,
        },
        valid: false,
        cdImpressoraCertificadoRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 40 || 'Quantidade de Caracteres superior que o necessario',
        ],
        carregarCombo: false,
      }
    },
    async created () {
      // await this.getList()
      // var dadosThis = this.caixadados

      setTimeout(() => {
        this.carregarCombo = true
      }, 1000)
    },
    mounted () {
      this.localImpressaodados.cdloja = this.cdloja
      this.localImpressaodados.cdusuario = this.cdusuario
      this.localImpressaodados.cdimpressora = this.cdimpressora
      this.localImpressaodados.cdimpressoracertificado = this.cdimpressoracertificado
    },
    methods: {
      onSair () {
        this.$emit('onSairCadastro')
      },
      onSave () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setSplashScreen', true)
          if (this.editar === false) {
            this.localImpressaodados.editar = false
            // this.localImpressaodados.cdLoja = parseInt(this.localImpressaodados.cdLoja)
            localImpressaoRep.Save(this.localImpressaodados).then(response => {
              if (response.data.status === 'sucesso') {
                this.$emit('onSucesso')
              } else if (response.data.status === 'messagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
          } else {
            this.$store.commit('setSplashScreen', true)
            this.localImpressaodados.editar = true
            // this.localImpressaodados.cdLoja = parseInt(this.localImpressaodados.cdLoja)
            localImpressaoRep.Save(this.localImpressaodados).then(response => {
              // listaUsuarios = response.data
              if (response.data.status === 'sucesso') {
                this.$emit('onSucesso')
              } else if (response.data.status === 'messagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
          }
        }
      },
      onSelectLoja (cdLoja) {
        this.localImpressaodados.cdLoja = cdLoja
      },
      onSelectImpressora (cdImpressora) {
        this.localImpressaodados.cdImpressora = cdImpressora
      },
      onSelectUsuario (cdUsuario) {
        this.localImpressaodados.cdUsuario = cdUsuario
      },
    },
  }
</script>
