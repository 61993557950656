<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Lista de Local de Impressão"
      class="px-5 py-3"
    >
      <v-row>
        <v-col class="text-right">
          <v-btn
            outlined
            @click="onNovoCadastro"
          >
            Cadastrar
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          Lista de Local de Impressão
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          dense
          :headers="headers"
          :items="listaLocalImpressao"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <!--<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  color="blue"
                  @click="onEditar(item)"
                  v-on="on"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>-->
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  v-bind="attrs"
                  class="ml-3"
                  @click="onExcluir(item)"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </base-material-card>
    <v-row justify="center">
      <v-dialog
        v-model="dialogNovo"
        persistent
        max-width="800"
      >
        <modalLocalImpressao
          v-if="dialogNovo"
          :editar="editar"
          :cdloja="cdloja"
          :cdusuario="cdusuario"
          :cdimpressora="cdimpressora"
          :cdimpressoracertificado="cdimpressoracertificado"
          @onSairCadastro="onSairCadastro"
          @onSucesso="onSucesso"
        />
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="dialogExcluir"
      persistent
      max-width="800"
    >
      <modalLocalImpressaoExcluir
        v-if="dialogExcluir"
        :cdloja="cdloja"
        :cdusuario="cdusuario"
        :cdimpressora="cdimpressora"
        :cdimpressoracertificado="cdimpressoracertificado"
        @onSairCadastro="onSairCadastro"
        @onSucesso="onSucesso"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import localImpressaoRep from '../../components/services/localimpressaoRepository'
  import modalLocalImpressao from './../../pages/componentes/componente-localimpressao-cadastro'
  import modalLocalImpressaoExcluir from './../../pages/componentes/componente-localimpressao-excluir.vue'

  export default {
    components: {
      modalLocalImpressao,
      modalLocalImpressaoExcluir,
    },
    data () {
      return {
        search: '',
        listaLocalImpressao: [],
        headers: [
          {
            text: 'Loja',
            align: 'center',
            value: 'cdLoja',
            width: 80,
          },
          {
            text: 'Usuário',
            align: 'start',
            value: 'cdUsuario',
          },
          {
            text: 'Impressora',
            align: 'start',
            value: 'cdImpressora',
          },
          {
            text: 'Certificado',
            align: 'start',
            value: 'cdImpressoraCertificado',
          },
          { text: '', value: 'actions', sortable: false },
        ],
        editar: false,
        idLocalImpressao: 0,
        // nomeCaixa: '',
        // Dialogs
        dialogNovo: false,
        dialogExcluir: false,
      }
    },
    async created () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        this.$swal({
          icon: 'warning',
          text: 'Acesso não liberado!',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) this.$router.replace('/v1')
        })
      } else {
        await this.getList()
      }
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await localImpressaoRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaLocalImpressao = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSairCadastro () {
        this.dialogNovo = false
        this.dialogExcluir = false
      },
      onSucesso () {
        this.dialogNovo = false
        this.dialogExcluir = false
        this.getList()
      },
      onNovoCadastro () {
        this.dialogNovo = true
        this.idLocalImpressao = 0
        this.editar = false
      },
      onEditar (item) {
        this.editar = true
        this.cdloja = item.cdLoja
        this.cdusuario = item.cdUsuario
        this.cdimpressora = item.cdImpressora.trim()
        this.cdimpressoracertificado = item.cdImpressoraCertificado.trim()
        this.dialogNovo = true
      },
      onExcluir (item) {
        this.cdloja = item.cdLoja
        this.cdusuario = item.cdUsuario.trim()
        this.cdimpressora = item.cdImpressora.trim()
        this.cdimpressoracertificado = item.cdImpressoraCertificado.trim()
        this.dialogExcluir = true
      },
    },
  }
</script>
