<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="#B71C1C"
      >
        <v-toolbar-title>
          Deseja excluir o local de impressão <span class="pl-3">{{ cdimpressora }}?</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onExcluir"
        >
          <v-icon class="white--text">
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-card>
</template>
<script>
  import localImpressaoRep from './../../components/services/localimpressaoRepository'
  export default {
    props: {
      cdloja: {
        type: Number,
        default: 0,
      },
      cdusuario: {
        type: String,
        default: '',
      },
      cdimpressora: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        localimpressao: { },
      }
    },
    methods: {
      async onExcluir () {
        try {
          this.$store.commit('setSplashScreen', true)
          this.localimpressao.cdloja = this.cdloja
          this.localimpressao.cdusuario = this.cdusuario
          this.localimpressao.cdimpressora = this.cdimpressora
          await localImpressaoRep.Delete(this.localimpressao).then(response => {
            if (response.data.status === 'sucesso') {
              this.$emit('onSucesso')
            } else if (response.data.status === 'messagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.messagem,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
            this.$store.commit('setSplashScreen', false)
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSair () {
        this.$emit('onSairCadastro')
      },
    },
  }
</script>
