<template>
  <v-select
    v-model="cdImpressora"
    :items="listaImpressora"
    item-text="cdImpressora"
    item-value="cdImpressora"
    label="Impressora"
    outlined
    hide-details
    :dense="dense"
    @change="onSelect"
  />
</template>
<script>
  import impressoraRep from './../../components/services/impressoraRepository'

  export default {
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        listaImpressora: [],
        cdImpressora: '',
      }
    },
    created () {
      this.getList()
      this.id === '' ? this.cdImpressora = '' : this.cdImpressora = this.id
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await impressoraRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaImpressora = response.data.dados
            } else if (response.data.status === 'messagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.messagem,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSelect () {
        this.$emit('onSelectImpressora', this.cdImpressora)
      },
    },
  }
</script>
